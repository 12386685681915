export function initDropdowns() {
	const dropdowns = document.querySelectorAll(".dropdown");
	dropdowns.forEach(dropdown => {
		const trigger = dropdown.querySelector(
			".dropdown__trigger:not([data-disabled])"
		);
		if (!trigger) {
			return;
		}
		const inputField = dropdown.querySelector(".dropdown__input");
		const clear = dropdown.querySelector(".dropdown__clear");
		const valueList = dropdown.querySelector(".dropdown__values-list");
		const valueListOptions = dropdown.querySelectorAll(
			".dropdown_values-list--option"
		);

		let valueArray = [];
		valueListOptions.forEach(option => {
			valueArray.push(option.textContent);
		});

		const open = function() {
			dropdown.classList.add("open");
		};

		const close = function() {
			dropdown.classList.remove("open");
		};

		trigger.addEventListener("click", e => {
			e.preventDefault();
			if (e.target != clear) {
				open();
			} else {
				close();
			}
		});

		const searchField = dropdown.querySelector(".dropdown__search > input");
		if (searchField) {
			searchField.value = "";
			searchField.addEventListener("input", e => {
				let inputValue = e.target.value;
				if (inputValue.length > 0) {
					for (let j = 0; j < valueArray.length; j++) {
						let val = valueArray[j].toLowerCase();
						if (val.includes(inputValue)) {
							valueListOptions[j].classList.remove("hidden");
						} else {
							valueListOptions[j].classList.add("hidden");
						}
					}
				} else {
					for (let i = 0; i < valueListOptions.length; i++) {
						valueListOptions[i].classList.remove("hidden");
					}
				}
			});
		}

		const setValue = (value, display) => {
			var event = new Event("updated");
			inputField.value = value;
			trigger.querySelector("span").innerText = display;
			inputField.dispatchEvent(event);
		};

		if (clear) {
			clear.addEventListener("click", e => {
				setValue("", "");
				trigger.querySelector("span").innerText = dropdown.getAttribute(
					"data-placeholder"
				);
				close();
			});
		}

		valueListOptions.forEach(item => {
			item.addEventListener("click", evt => {
				let newValue = item.dataset["value"];
				setValue(newValue, item.textContent);
				close();
			});
		});

		document.addEventListener("click", evt => {
			const isDropdown = valueList.contains(evt.target);
			const isInput = trigger.contains(evt.target);
			if (!isDropdown && !isInput) {
				close();
			}
		});
	});
}
