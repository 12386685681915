import "../scss/app.scss";

// SVGs to make into sprite, paths relative to app.js before move
var __svg__ = {
	path: "../svgs/sprite/*.svg",
	name: "../../diemens/jinja2/svgs/sprite.svg"
};

// Polyfill all the things...
import "whatwg-fetch";
// For anything not-DOM (eg String.repeat, Promise, Array.fill etc) use core-js.
// See available options here -> https://github.com/zloirock/core-js#features
import "core-js/es/array/from";
import "core-js/features/promise";

// We manually include the DOM polyfills we believe are necesary.
import applyDomPolyfills from "./includes/dompolyfills";

applyDomPolyfills();

// Production code goes after here
import { OutdatedBrowserNotice } from "@neonjungle/birdseed/browser/OutdatedBrowserNotice";

import initMap from "./includes/gmaps";
import menuActions from "./includes/menuActions";
import updateClampedCSS from "./includes/updateClampedCSS";
import awardAnimation from "./includes/awardAnimation";
import peopleGridInteraction from "./includes/peopleGridInteraction";
import initAccordions from "./includes/accordion";
import { archiveFilter } from "./includes/archiveFilter";
import { initDropdowns } from "./includes/dropdowns";

// Should happen before the page has finished loading
// to update font sizes ASAP
updateClampedCSS();

document.addEventListener("DOMContentLoaded", function() {
	window.initMap = initMap;
	menuActions();
	awardAnimation();
	peopleGridInteraction();
	initAccordions(true);
	archiveFilter();
	initDropdowns();

	new OutdatedBrowserNotice(false, browserOutdated => {
		if (browserOutdated) {
			document.body.classList.add("browser-is-outdated");
		}
	});

	document.body.classList.remove("preload");

	document.querySelector(
		'[data-subscribe]'
	).addEventListener('submit', e => {
		e.preventDefault();
		let form = e.target;
		form.classList.add('thinking');
		let messageContainer = form.querySelector('.l-contact--form-message')
		let data = new FormData(form);
		fetch(form.action, {
			'method': 'POST',
			'body': data,
		}).then(response => response.json())
		.then(json => {
			if(json.success) {
				messageContainer.innerText = 'Thank you for subscribing, we will be in touch shortly.'
			} else {
				messageContainer.innerText = 'There was a problem signing you up, an administrator has been notified.'
			}
			form.classList.remove('thinking');
			messageContainer.classList.add('show')
		})
	})
});
