export default function() {
	if(!window.appData['GMAPS_ADDRESS']) {
		console.log('No address found for google maps')
		return;
	}
	if(!google) {
		console.log('Google API not found');
		return;
	}
	var geocoder = new google.maps.Geocoder();
	geocoder.geocode({'address': window.appData['GMAPS_ADDRESS']}, function(results, status){
		if (status == google.maps.GeocoderStatus.OK) {
			var map = new google.maps.Map(document.getElementById('map'), {
				zoom: 15,
				center: results[0].geometry.location,
				styles: [] // https://snazzymaps.com/,
			});
			var marker = new google.maps.Marker({
				position: results[0].geometry.location,
				map: map
			});
		} else {
			console.log('Could\'t find location for address:' + status)
		}
	});
}
