export default function() {
	const $personsGrid = document.querySelector("[data-persons-grid]");
	if (!$personsGrid) {
		return;
	}
	const $body = document.body;
	const $peopleTrigger = document.querySelectorAll("[data-person-trigger]");
	const $profileContainer = document.querySelector(
		"[data-person-profile-container]"
	);
	let isMobile = document.documentElement.clientWidth < 768;
	let profileOpen = false;

	if (!$peopleTrigger) return;

	let open = function() {
		profileOpen = true;
		$body.classList.add("profile-open");
		$profileContainer.classList.add("is-active");
		$personsGrid.classList.add("person-open");
	};

	let close = function() {
		profileOpen = false;
		$body.classList.remove("profile-open");
		$profileContainer.classList.remove("is-active");
		$personsGrid.classList.remove("person-open");
	};

	let debounceMouseLeaveTimeout;

	$peopleTrigger.forEach($j => {
		function handleInteraction(e) {
			e.preventDefault();
			const $personProfile = $j.querySelector("[data-person-profile]");
			$profileContainer.innerHTML = $personProfile.innerHTML;
			open();
			var profileTop = $personsGrid.parentNode.parentNode.offsetTop;
			if (isMobile) {
				profileTop -= 20;
			} else {
				profileTop -= 100;
			}
			window.scrollTo({
				top: profileTop,
				behavior: "smooth"
			});
		}
		$j.addEventListener("mousedown", handleInteraction);

		$j.addEventListener("mouseenter", e => {
			clearTimeout(debounceMouseLeaveTimeout);
			$personsGrid.classList.add("hovered");
		});

		$j.addEventListener("mouseleave", e => {
			debounceMouseLeaveTimeout = setTimeout(() => {
				$personsGrid.classList.remove("hovered");
			}, 200);
		});
	});

	function closePeople(e) {
		if (!profileOpen) {
			return;
		}
		e.preventDefault();
		let people = Array.from($peopleTrigger);
		let isProfile = false;
		people.every(p => {
			if (p.contains(e.target)) {
				isProfile = true;
			} else return true;
		});
		if (!isProfile) {
			close();
		}
	}

	document.addEventListener("mousedown", closePeople);

	let resizeTimer;
	window.addEventListener("resize", _ => {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(_ => {
			isMobile = document.documentElement.clientWidth < 768;
		});
	});
}
