export default function() {
	const canvas = document.querySelector("#award-animation");
	if (!canvas) {
		return;
	}
	const stage = document.querySelector("#stage");
	const container = stage.parentElement;
	const content = container.querySelector('[data-award-content]');
	const CONTENT_OFFSET = 212; // size of 'THE' 'Diemen' 'Awards'
	const initialClientWidth = document.documentElement.clientWidth;
	let animationFinished = false;
	let firstAwardDraw = true;
	let isMobile = document.documentElement.clientWidth < 1024;
	var backgroundImg = new Image();
	var awardImg = new Image();
	backgroundImg.src = stage.getAttribute("data-background-gradient");
	awardImg.src = stage.getAttribute("data-award-img");

	// Scale canvas to fit stage width
	const canvasScale = () => {
		let stageSize = stage.getBoundingClientRect();
		var scaleX = stageSize.width / canvas.width;
		var scaleY = stageSize.height / canvas.height;

		var scaleToFit = Math.min(scaleX, scaleY);
		if (isMobile) {
			canvas.style.height = scaleToFit * stageSize.height + "px";
		} else {
			canvas.style.transformOrigin = "0 0"; //scale from top left
			canvas.style.transform = "scale(" + scaleX + ")";
		}
		content.style.top = `${stageSize.height - CONTENT_OFFSET}px`;
	};
	canvasScale();
	let resizeTimeout = null;
	window.addEventListener("resize", _ => {
		resizeTimeout = null;
		if (initialClientWidth != document.documentElement.clientWidth) {
			resizeTimeout = setTimeout(canvasScale, 200);
		}
	});

	const ctx = canvas.getContext("2d");

	let canvasHeight = canvas.height;
	let canvasWidth = canvas.width;
	const triangleWidth = 262;
	const halfTriangleWidth = triangleWidth / 2;
	const triangleCount = (canvasWidth / triangleWidth) * 2 + 2;
	const convergePoint = canvasWidth / 2 + 40;
	const triangleAnimationPercentage = 0.8;
	const completeHalfTriangles = Math.floor(canvasWidth / halfTriangleWidth);
	const incompleteTriangleWidth =
		canvasWidth - completeHalfTriangles * halfTriangleWidth;
	let containerStyle = getComputedStyle(container);
	let containerOffset = container.offsetTop;
	let containerHeight = container.getBoundingClientRect().height;
	let canvasRealHeight = canvas.getBoundingClientRect().height;
	let animationYEnd =
		containerOffset +
		containerHeight -
		parseFloat(containerStyle.paddingTop) -
		canvasRealHeight;

	let scrollLimit = function(event) {
		let nextYOffset = event.deltaY + window.pageYOffset;
		if (nextYOffset > animationYEnd) {
			event.preventDefault();
			finishAnimation();
			return;
		}
	};
	if (!isMobile) {
		window.addEventListener("wheel", scrollLimit, { passive: false });
	}

	const map = (value, x1, y1, x2, y2) => {
		return ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;
	};

	const getElementHeight = el => {
		return el.getBoundingClientRect()["height"];
	};

	const getPercentage = () => {
		let stageOffset = stage.offsetTop - container.offsetTop;
		if (!stageOffset || stageOffset <= 70) {
			return 0;
		}
		let travelDistance =
			getElementHeight(container) - getElementHeight(stage) - 70;
		let percentage = (stageOffset - 70) / travelDistance;
		return percentage;
	};

	const drawAward = stepPercentage => {
		let opacity = map(
			stepPercentage,
			triangleAnimationPercentage,
			1.0,
			0.0,
			1.0
		);
		ctx.globalAlpha = opacity;
		ctx.drawImage(awardImg, convergePoint, 0, triangleWidth, canvas.height);
		if(firstAwardDraw) {
			container.classList.add("show-text");
		}
		firstAwardDraw = false;

	};

	const drawTriangle = x => {
		ctx.beginPath();
		ctx.moveTo(x, canvasHeight); // Bottom left
		ctx.lineTo(x + halfTriangleWidth, 0); // Top Center
		ctx.lineTo(x + triangleWidth, canvasHeight); // Bottom Right
		ctx.lineTo(x + 1 + triangleWidth, canvasHeight); // Bottom Right
		ctx.lineTo(x + 1 + halfTriangleWidth, 0); // Top Center
		ctx.lineTo(x + 1, canvasHeight); // Bottom left
		ctx.closePath();
		ctx.fill();
	};

	const drawTriangles = stepPercentage => {
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		let opacity = map(
			stepPercentage,
			triangleAnimationPercentage,
			0.9,
			1.0,
			0.0
		);
		if (opacity < 0.15) return;
		ctx.save();
		ctx.globalAlpha = opacity;
		let triangleStepPercentage = map(
			stepPercentage,
			0.0,
			triangleAnimationPercentage,
			0.0,
			1.0
		);
		triangleStepPercentage = Math.min(triangleStepPercentage, 1.0);
		let triangleStartingPoint = -(halfTriangleWidth + incompleteTriangleWidth);

		let triangleIndex = 0;
		while (triangleIndex < triangleCount) {
			let offset =
				triangleStartingPoint +
				triangleStepPercentage * (convergePoint - triangleStartingPoint);
			drawTriangle(offset);
			triangleStartingPoint += halfTriangleWidth;
			triangleIndex++;
		}
		ctx.globalCompositeOperation = "source-atop";

		ctx.drawImage(backgroundImg, 0, 0, canvas.width, canvas.height);
		ctx.restore();
	};

	const render = () => {
		let stepPercentage = getPercentage();
		let renderSteps = [];

		if (isMobile) {
			stepPercentage = 0;
			drawTriangles(stepPercentage);
			finishAnimation();
			return;
		}

		if (stepPercentage < 0.998 && !animationFinished) {
			renderSteps.push(drawTriangles);
			if (triangleAnimationPercentage > stepPercentage < 1) {
				renderSteps.push(drawAward);
			}
			requestAnimationFrame(_ => {
				for (let i = 0; i < renderSteps.length; i++) {
					renderSteps[i](stepPercentage);
				}
				render();
			});
		} else {
			requestAnimationFrame(_ => {
				drawTriangles(1);
				drawAward(1);
			});
			finishAnimation();
		}
	};

	const finishAnimation = () => {
		animationFinished = true;
		setTimeout(function() {
			if (!isMobile) {
				window.scrollTo({
					top: container.offsetTop,
					left: 0,
					behavior: "instant"
				});
				container.style.height = `${getElementHeight(canvas)}px`;
				container.classList.add("is-finished");

			}
			window.removeEventListener("wheel", scrollLimit);
		}, 1);
	};

	backgroundImg.onload = function() {
		render();
	};
}
