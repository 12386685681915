import { AjaxForm } from "@neonjungle/birdseed/forms/ajax";
import { initDropdowns } from "./dropdowns";

let initForm = function() {
	let form = document.querySelector("[data-ajax-form]");
	let target = document.querySelector("[data-ajax-target]");
	const ajaxForm = new ArchiveAjaxForm(form, target);
	const mobileFilter = form.querySelector("[data-mobile-filter]");
	const clearBtn = form.querySelector(".l-form-controls--clear-btn.active");
	let isMobile = document.documentElement.clientWidth < 1024;

	initDropdowns();

	if (clearBtn) {
		clearBtn.addEventListener("click", e => {
			form.querySelectorAll(".dropdown__input").forEach(dt => {
				dt.value = "";
			});
			ajaxForm.fetchAndReplace();
		});
	}

	form.querySelector("[data-filter-open]").addEventListener("click", e => {
		mobileFilter.classList.add("is-active");
	});

	form.querySelector("[data-filter-close]").addEventListener("click", e => {
		mobileFilter.classList.remove("is-active");
	});

	form.querySelector("[data-filter-submit]").addEventListener("click", e => {
		mobileFilter.classList.remove("is-active");
		ajaxForm.fetchAndReplace();
	});

	form.querySelectorAll(".dropdown__input").forEach(dt => {
		dt.addEventListener("updated", e => {
			if (!isMobile) ajaxForm.fetchAndReplace();
		});
	});

	let resizeTimer;
	window.addEventListener("resize", _ => {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(_ => {
			isMobile = document.documentElement.clientWidth < 1024;
			form.querySelectorAll(".dropdown__trigger-input").forEach(dt => {
				if (isMobile) {
					dt.setAttribute("readonly", "");
				} else {
					dt.removeAttribute("readonly");
				}
			});
		});
	});

	let fetching = false;
	const observerCallback = (entries, observer) => {
		if (!entries[0].isIntersecting) return;
		if (fetching) return;
		fetching = true;
		observer.disconnect(); // Once off
		const target = entries[0].target;
		target.innerHTML = "Loading...";
		const nextURL = target.getAttribute("data-next-page");

		fetch(nextURL, {
			method: "GET",
			credentials: "same-origin",
			cache: "no-store",
			headers: {
				"X-Requested-With": "XMLHttpRequest",
				"X-ENDLESS-PAGINATION": "true"
			}
		})
			.then(response => response.json())
			.then(responseJson => {
				if (responseJson.success) {
					let temp = document.createElement("template");
					temp.innerHTML = responseJson.payload;
					target.replaceWith(temp.content);
					setupPaginator();
					fetching = false;
				} else {
					ajaxForm.handleError(responseJson.message);
					fetching = false;
				}
			});
	};

	const nextObserver = new IntersectionObserver(observerCallback);
	const setupPaginator = _ => {
		const nextPageTrigger = document.querySelector("[data-next-page]");
		if (nextPageTrigger) {
			nextObserver.observe(nextPageTrigger);
		}
	};
	setupPaginator();
};

class ArchiveAjaxForm extends AjaxForm {
	afterFetch() {
		initForm();
		super.afterFetch();
		const formData = new FormData(this.form);
		formData.delete("csrfmiddlewaretoken");
		const parameters = Array.from(formData.entries())
			.map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
			.join("&");
		const pushedURL = `${this.form.action}?${parameters}`;
		history.pushState({}, "", pushedURL);
	}
}

export function archiveFilter() {
	if (!document.querySelector(".l-archive")) {
		return;
	}
	initForm();
}
